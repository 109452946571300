<template>
  <div class="content" style="margin-top: 1rem">
    <div class="h3 text-white font-weight-bold text-center position-relative">
      <div class="change family">{{ $route.meta.title }}</div>
      <!-- <router-link to="/active/creation/pkHome" class="content-add-create f_btn  position-absolute right-0 top-0">
        创建竞技场
      </router-link> -->
    </div>
    <div class="content-three">
      <div
        v-for="(item, index) in mysteryNav"
        :key="index"
        @click="navIndexClick(item, index)"
        class="family"
      >
        {{ item.name }}
      </div>
    </div>

    <!-- <div class="history_bg mt-3 content-lockbox" style="padding:5px">
      <div class="d-flex content-lockbox-list  content-lockbox-list-height">
        <div class="cursor-pointer content-lockbox-list-item d-flex flex-wrap justify-content-center"
          v-for="item, index in boxGunList" :key="index">
          <img height="80px" src="" alt="">
          <div style="font-size: 12px;line-height: 24px" class="text-overflow-1">
            {{ item.title }}
          </div>
          <div style="font-size: 12px;" class="align-items-center d-flex justify-content-center w-100">
            <money style="font-size: 12px;"></money>{{ item.game_bean }}
          </div>
        </div>

      </div>
    </div> -->
    <div class="list">
      <!-- <div class="lists" v-for="item in boxGunList" :key="item.id"> -->
      <div
        class="lists"
        v-for="(item, index) in boxGunList"
        :key="index"
        @click="lockBoxGun(item, index)"
      >
        <div class="listName">
          <!-- <span>CS：GO10周年印花胶囊</span> -->
          <span style="font-size: 8px">{{ item.name }}</span>
        </div>
        <div class="img">
          <img :src="item.intact_cover" alt="" width="80px" height="80px" />
        </div>
        <div class="listBtm">
          <img src="@/assets/image/userCenter/u8_icon.png" alt="" />
          <span style="font-size: 8px">{{ item.game_bean }}</span>
        </div>
      </div>
    </div>

    <!-- 首页玩家 -->
    <div>
      <div class="player family">玩家</div>
      <!-- <div v-show="lockBoxGunIsShow">
      <div style="" class="position-fixed pop-up content-pop z-index-100">
        <div>
          <div class="h5 mt-3 ml-3">包含以下奖励</div>
          <div class="d-flex flex-wrap content-pop-lock align-content-start">

            <div v-for="(item, index) in boxGunListContains" :key="index"
              class="content-pop-lock-item mb-3 col-lg-3 col-md-3 col-6 " :class="'ggbg' + item.level">
              <img width="100%" class="mx-auto d-block mt-3" :src="item.skins.cover" alt="">
              <div class="text-center px-3 text-overflow-1">
                <div class="gunboxtext ">{{ item.skins.name }}</div>
              </div>
              <div class="mx-auto d-flex justify-content-between align-items-center  text-center p-2  mt-2 cursor-pointer"
                style="">
                <div style="font-size: 12px;color:#ddd ">{{ item.skins.dura_alias }}</div>
                <div style="font-size: 12px; color: #75dc9e;">
                  <div>爆:{{ item.odds_percent }}</div>
                  <span style=" font-weight: 900; ">${{ item.skins.bean }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div @click=" lockBoxGunIsShow = false" class="position-absolute" style="right: 20px;top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
          </div>
        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
    </div> -->

      <div class="queue">
        <!-- 全部对战  +  排行榜 -->
        <div class="d-flex" v-if="navIndex == 0" style="width: 100%">
          <div class="d-flex pkbigbox" style="width: 100%">
            <div
              v-for="(item, index) in PKarenaList"
              :key="index"
              class="mb-3 animate__animated d-flex"
            >
              <div class="PK_phone">
                <index-p-k-list-phone
                  ref="pkListAll"
                  @delList="delList"
                  :p-k-arena-list-index="PKarenaListIndex"
                  :res-data="item"
                ></index-p-k-list-phone>
              </div>
            </div>
            <div
              v-if="PKarenaList.length == 0"
              class="m-auto"
              style="font-size: 16px"
            >
              <vacancy message="没有最新对战......"></vacancy>
            </div>
          </div>
        </div>
        <div v-if="navIndex == 1">
          <div
            v-for="(item, index) in HistoryListMe"
            :key="index + 'a'"
            class="mb-3 content-pk-list-item"
          >
            <div class="PK_pc">
              <index-pk-list ref="pkListAll" :res-data="item"></index-pk-list>
            </div>
            <div class="PK_phone">
              <index-p-k-list-phone :res-data="item"></index-p-k-list-phone>
            </div>
          </div>
          <div v-if="HistoryListMe.length == 0" style="font-size: 16px">
            <vacancy message="没有最新对战......"></vacancy>
          </div>
        </div>
        <!-- 历史记录 -->
        <div v-if="navIndex == 2">
          <div
            v-for="(item, index) in HistoryList"
            :key="index"
            class="mb-3 content-pk-list-item"
          >
            <div class="PK_pc">
              <index-pk-list :res-data="item"></index-pk-list>
            </div>
            <div class="PK_phone">
              <index-p-k-list-phone :res-data="item"></index-p-k-list-phone>
            </div>
          </div>
          <div v-if="HistoryList.length == 0" style="font-size: 16px">
            <vacancy message="没有最新对战......"></vacancy>
          </div>
        </div>
      </div>
    </div>

    <!-- 翻页 -->
    <div v-if="navIndex == 1 || navIndex == 2 || navIndex == 0">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :pager-count="5"
        @current-change="currentChange"
        prev-text="上一页"
          next-text="下一页"
      >
      </el-pagination>
    </div>
    <!-- 箱子里面展示的内容 -->
    <div v-if="lockBoxGunIsShow">
      <div
        class="position-fixed pop-up content-pop z-index-100"
        style="top: 12px"
      >
        <div>
          <div class="mt-3 ml-3">包含以下奖励</div>
          <div
            class="d-flex flex-wrap content-pop-lock align-content-start"
            style="padding-left: 15px"
          >
            <div
              v-for="(item, index) in boxGunListContains"
              :key="index"
              class="content-pop-lock-item col-lg-3 col-md-3 col-6"
            >
              <img
                width="100%"
                class="mx-auto d-block mt-3"
                :src="item.skins.cover"
                alt=""
              />
              <div class="text-center px-3 text-overflow-1">
                <div class="gunboxtext">{{ item.skins.name }}</div>
              </div>
              <div
                class="mx-auto d-flex justify-content-between align-items-center text-center p-2 mt-2 cursor-pointer"
                style=""
              >
                <div style="font-size: 12px; color: #ddd">
                  {{ item.skins.dura_alias }}
                </div>
                <div style="font-size: 12px; color: #75dc9e; font-weight: 900">
                  <money /> {{ item.skins.bean }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          @click="lockBoxGunIsShow = false"
          class="position-absolute"
          style="right: 20px; top: 30px"
        >
          <div>
            <i
              class="iconfont text-white icon-guanbi-02-02 cursor-pointer"
              style="font-size: 30px"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="position-fixed pk_gun_bg op-4 z-index-1"
        style="width: 100vw; height: 100vh; left: 0; top: 0"
      ></div>
    </div>
  </div>
</template>

<script>
import $api from "@/api/pk/index";
import indexPkList from "./common/indexPkList";
import IndexPKListZ from "./common/indexPKListZ";
import IndexPKListPhone from "./common/indexPKListPhone.vue";

export default {
  name: "index",
  data() {
    return {
      rankingPhone: true,
      mysteryNav: [
        { id: 1, name: "对战规则" },
        { id: 3, name: "对战历史" },
        { id: 2, name: "创建对战" },
      ],
      totaldata: 0,
      navIndex: 0,
      PKarenaList: [],
      total: 0,
      pageSize: 0,
      HistoryList: [],
      HistoryListMe: [],
      PKarenaListIndex: 0,
      pkUnderwayList: [],
      filterLiastIndex: 1,
      filterLiastIntob: null,
      oneLoading: false,
      page: 1,
      time: null,
      boxGunList: [],
      lockBoxGunIsShow: false,
      boxGunListContains: [],
      sum: "",
    };
  },
  components: {
    IndexPKListZ,
    IndexPKListPhone,
    indexPkList,
  },
  created() {
    this.initWebSocket();
  },
  mounted() {
    this.init();
    this.time = setInterval(async () => {
      try {
        let { data, timestamp } = await $api.getPKarenaList(this.page);

        let filterLiast = data.data.filter((item) => {
          if (item.status == 2) {
            item.ceil = 0;
            return item;
          }
        });

        filterLiast.forEach((item) => {
          let time1 = Date.parse(item.updated_at) / 1000;
          let time2 = timestamp;
          let integer = Math.ceil((time1 + item.box_num * 10 - time2) / 10);

          if (time2 + item.box_num * 10 > time1) {
            item.ceil = item.box_num - integer;
            item.status = 1;
          } else {
            data.data.forEach((t, i) => {
              if (t.id == item.id) {
                data.data.splice(i, 1);
              }
            });
          }
          if (integer < 0) {
            data.data.forEach((t, i) => {
              if (t.id == item.id) {
                data.data.splice(i, 1);
              }
            });
          }
        });
        this.PKarenaList = JSON.parse(JSON.stringify(data.data));
      } catch (e) {
        clearInterval(this.time);
      }
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.time);
    this.PKarenaList = [];
  },
  watch: {
    PKarenaListIndex: {
      handler(newVal) {
        this.PKarenaList = newVal;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 所有初始化
    async init() {
      this.getPKarenaList(this.page);
      this.getPKarenaboxList();
    },
    delList(data) {
      this.PKarenaList.forEach((item, index) => {
        if (item.id == data.id) {
          this.PKarenaList.splice(index, 1);
        }
      });
      window.sessionStorage.setItem("pk" + data.id, "0");
    },
    async getPKarenaList(page = 1) {
      try {
        let { data, code, message, timestamp } = await $api.getPKarenaList(
          page
        );
        if (code == 500) return;
        if (code != 200) {
          this.$message.error(message);
        }
        let filterLiast = data.data.filter((item) => {
          item.ceil = 0;
          return item.status == 2;
        });
        filterLiast.forEach((item) => {
          let time1 = Date.parse(item.updated_at) / 1000;
          let time2 = timestamp;
          let integer = Math.ceil((time1 + item.box_num * 10 - time2) / 10);

          if (time2 + item.box_num * 10 > time1) {
            item.ceil = item.box_num - integer;
            item.status = 1;
          } else {
            data.data.forEach((t, i) => {
              if (t.id == item.id) {
                data.data.splice(i, 1);
              }
            });
          }
          if (integer < 0) {
            data.data.forEach((t, i) => {
              if (t.id == item.id) {
                data.data.splice(i, 1);
              }
            });
          }
        });
        filterLiast.forEach((item, index) => {
          filterLiast.forEach((t) => {
            if (item.id == t.id) {
              filterLiast.splice(index, 1);
            }
          });
        });
        this.PKarenaList = [...data.data];
        this.total = data.total;
        this.pageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },
    async getPKarenaHistory(page = 1) {
      try {
        let { data, code, message } = await $api.getPKarenaMyHistory(page);
        if (code == 500) return;
        if (code != 200) {
          this.$message.error(message);
        }

        data.data.forEach((item) => {
          item.game_arena_player.forEach((t) => {
            let index = item.win_user_id.find((e) => {
              return t.user_id == e;
            });
            t.user_id = "";
          });
        });

        this.HistoryList = data.data;

        this.total = data.total;
        this.pageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },
    async getPKarenaMyHistory(page = 1) {
      try {
        let { data, code, message } = await $api.getPKarenaHistory(page);
        if (code == 500) return;
        if (code != 200) {
          this.$message.error(message);
        }

        data.data.forEach((item) => {
          item.game_arena_player.forEach((t) => {
            let index = item.win_user_id.find((e) => {
              return t.user_id == e;
            });
            if (index) {
              t.success = true;
            }
            t.user_id = "";
          });
        });
        console.log(data.data);

        this.HistoryListMe = data.data;

        this.total = data.total;
        this.pageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },
    currentChange(val) {
      this.page = val;
      if (this.navIndex == 0) {
        this.getPKarenaList(val);
      }
      if (this.navIndex == 1) {
        this.getPKarenaMyHistory(val);
      }
      if (this.navIndex == 2) {
        this.getPKarenaHistory(val);
      }
      window.scrollTo(0, 0);
    },
    navIndexClick(item) {
      // this.navIndex = index
      // this.currentChange(1)
      if (item.id == 2) {
        this.$router.push({ name: "creationpkHome" });
      } else if (item.id == 1) {
        this.$router.push({ name: "pkrule" });
      } else if (item.id == 3) {
        this.$router.push({ name: "pkhistory" });
      }
    },
    async getPKarenaboxList() {
      try {
        let { data, code } = await $api.getPKarenaboxList();
        if (code == 500) return;
        this.boxGunList = data.map((item) => {
          item.active = false;
          return item;
        });
      } catch (e) {
        console.log(e);
      }
      // console.log(this.boxGunList, '1111111111111111111111111111111111')
    },
    lockBoxGun(item) {
      this.lockBoxGunIsShow = true;
      this.boxGunListContains = item.contains;
    },
    initWebSocket() {
      let _this = this;
      _this.$ws.addEventListener("message", (event) => {
        let data = event.data;
        let { Data, MsgType } = JSON.parse(data);
        if (MsgType == "CreateGroup") {
          this.PKarenaList.unshift(Data);
        }
        if (MsgType == "joinGroup") {
          let data = {
            seat: Data.seat,
            game_arena_id: Data.game_arena_id,
            user: {
              id: Data.user_id,
              name: Data.name,
              avatar: Data.avatar,
            },
          };
          _this.PKarenaList.forEach((item) => {
            if (item.id == Data.game_arena_id) {
              item.game_arena_player.push(data);
            }
          });
        }
        if (MsgType == "startGroup") {
          this.PKarenaList.forEach((item) => {
            if (item.id == Data.game_arena_id) {
              item.status = Data.status;
            }
          });
        }
        if (MsgType == "endGroup") {
          let time = Data.box_num;

          setTimeout(() => {
            _this.PKarenaList.forEach((item) => {
              if (item.id == Data.id) {
                item.status = Data.status;
              }
            });
          }, time * 10000);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.change {
  width: 310px;
  height: 71px;
  margin: auto;
  font-size: 36px;
  background-image: url(../../assets/image/12.png);
  background-size: 100% 100%;
  text-align: center;
  line-height: 71px;
  font-weight: 400;
  -webkit-text-stroke: 1px #000000;
}

.content-three {
  width: 309px;
  height: 32px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  div {
    width: 78px;
    height: 32px;
    background-image: url(../../assets/image/13.png);
    background-size: 100% 100%;
    font-size: 12px;
    text-align: center;
    line-height: 32px;
    color: RGBA(0, 159, 255, 1);
  }
}

.list::-webkit-scrollbar {
  display: none;
}

.list {
  width: 100%;
  height: 175px;
  overflow: auto;
  margin-top: 25px;
  display: flex;

  .lists {
    width: 100px;
    height: 150px;
    background: linear-gradient(180deg, #9fda35 0%, rgba(159, 218, 53, 0) 100%);
    border-radius: 13px 13px 13px 13px;
    opacity: 1;
    margin-right: 10px;
    text-align: center;

    .listName {
      width: 80px;
      margin-left: 5px;
    }

    .img {
      margin: 5px auto;
      width: 80px;
      height: 80px;
      // background: #FFFFFF;
      border-radius: 13px 13px 13px 13px;
      opacity: 1;
    }

    .listBtm {
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 4px;
      }
    }
  }
}

.player {
  margin-top: 22px;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #9fda35;
}

.queue {
  margin-top: 25px;
  display: flex;
  justify-content: space-around;

  .queue-red {
    width: 171px;
    height: 237px;
    background-image: url(../../assets/image/15.png);
    background-size: cover;

    .top {
      height: 20px;
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
      }

      div {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .main {
      margin-top: 12px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
      }

      div {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .main-up {
      height: 30px;
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background-color: RGBA(118, 118, 118, 1);
        border-radius: 50%;
        border: 2px solid RGBA(159, 218, 53, 1);
      }

      .main-right {
        border: 2px dashed RGBA(159, 218, 53, 1);
        background: transparent;
        text-align: center;
        color: RGBA(159, 218, 53, 1);
      }
    }

    .bottom-top {
      margin-top: 45px;
      height: 32px;
      display: flex;
      justify-content: center;

      img {
        display: block;
        width: 32px;
        height: 32px;
      }
    }

    .bottom {
      margin-top: 6px;
      height: 40px;
      background-color: #000;

      img {
        height: 40px;
      }
    }
  }
}

.ranking-phone {
  display: none;

  @media (max-width: 960px) {
    display: block;
    position: fixed;
    top: 40%;
    left: 0;
    width: 25px;
    text-align: center;
    padding: 10px 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    line-height: 1.5;
    background-color: #4c4b57;
    z-index: 999;
  }
}

.gunboxtext {
  font-size: 12px;
  position: absolute;
  top: 10px;
  color: #ddd;
}

.content-top-sum {
  width: 50%;
}

@media (max-width: 1200px) {
  .content-pk-list-item {
    width: 50% !important;
    margin: 15px auto !important;
  }
}

@media (max-width: 960px) {
  .content-pk-list-item {
    width: 100% !important;
    margin: 15px auto !important;
  }
}

.content {
  width: 90%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 96%;
    left: 2%;
  }

  &-add {
    width: 100%;
    height: 160px;
    display: flex;

    &-create {
      background-color: #28283b;
      color: #fff;
      font-weight: bold;
      color: #ffffff;
      font-size: 16px;
      padding: 15px 35px;

      @media (max-width: 1200px) {
        position: initial !important;
        margin-top: 10px;
      }
    }
  }

  &-nav {
    height: 70px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid var(--main-blue);

    &-item {
      height: 70px;
      padding: 0 30px;
      line-height: 70px;

      @media (max-width: 1200px) {
        padding: 0 6px;
        font-size: 10px;
      }
    }

    & .active {
      color: var(--f-border-yellow);
      border-bottom: 2px solid var(--f-border-yellow);
      /*background-image: linear-gradient(0deg,rgba(251,202,1,.3), rgba(0,0,0,0));*/
    }
  }

  &-pk {
    &-list {
      &-item {
        margin: 15px 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        background: var(--f-main);
        border-radius: 5px;
      }
    }
  }
}

.content-lockbox {
  &-list {
    margin-top: 20px;
    width: 100%;

    &-item {
      width: 125px;
      height: 125px;
      margin-bottom: 10px;
      text-align: center;
      padding: 5px;

      @media (max-width: 960px) {
        width: 100px;
        height: 100px;
        padding: 0;
        margin-bottom: 40px;
      }
    }

    &-height {
      overflow: auto;

      @media (max-width: 960px) {
        height: 150px !important;
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: transparent;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: transparent;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
      }
    }
  }

  & .active {
    background-color: var(--f-main);
  }
}

.content-pop {
  width: 800px;
  left: calc(50% - 350px);
  top: 100px;

  &-box {
    height: 450px;
    width: 720px;
    margin: 0 auto;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      border: 1px solid #000000;
      width: 160px !important;
      background-color: #090827;

      &:hover &-add {
        display: block;
      }

      &-add {
        display: none;
      }
    }

    & .active {
      background-color: var(--main-yellow);
    }
  }

  &-lock {
    margin-top: 20px;
    height: 550px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 30px;

    @media (max-width: 960px) {
      padding: 10px;
    }

    &-item {
      background-color: #00000060;
      border: transparent !important;
      border-radius: 15px;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    left: 0;
    height: 650px;
  }
}
.PK_phone {
  width: 175px;
  margin-right: 10px;
}
.animate__animated {
  width: 50%;
}
.pkbigbox {
  flex-wrap: wrap;
}
</style>
